export default {
  publicCollectionItemCard: {
    collector: "Zberateľ",
    offeringFor: "Cena",
    notForSale: "nie je na predaj",
    amount: "Množstvo",
    amountUnit: "ks",
    imInterested: "Mám záujem",
  },
};
