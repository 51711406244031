import { Patron } from "@/composables/patrons";
import { CategoryIdOrAll, ValueAllCategories } from "@/services/repositories/collection/Collection";
import { gettext, translate } from "@/services/translation";
import { CategoryId } from "@/types";

export type LandingPageSetup = {
  categoryId: CategoryIdOrAll;
  heroImage: { url: string; mime: string }[];
  heroText: string[];
  itemsText: string;
  collectorsText: string;
  magazineText: string;
  patronsText: string;
  patrons: Patron[];
};

export const factoryLandingPageSetupHomepage = (): LandingPageSetup => ({
  categoryId: ValueAllCategories,
  heroImage: [
    { url: "/images/homepage/collage-all-categories.webp", mime: "image/webp" }, //
    { url: "/images/homepage/collage-all-categories.avif", mime: "image/avif" },
    { url: "/images/homepage/collage-all-categories.png", mime: "image/png" },
  ],
  heroText: [
    translate("views.publicHomepage.hero.categories.lego"), //
    translate("views.publicHomepage.hero.categories.watch"),
    translate("views.publicHomepage.hero.categories.vinyls"),
    translate("views.publicHomepage.hero.categories.art"),
    translate("views.publicHomepage.hero.categories.alcohol"),
    translate("views.publicHomepage.hero.categories.cards"),
  ],
  itemsText: gettext.$gettext("Sbírej, prodávej, vyměňuj. To vše je možné v&nbsp;naší sběratelské sekci."),
  collectorsText: gettext.$gettext("Prohlédni si portfolia ostatních sběratelů a&nbsp;objev, kdo má stejnou vášeň jako ty."),
  magazineText: gettext.$gettext("Sleduj náš magazín a&nbsp;objevuj novinky a&nbsp;tipy ze světa sběratelů."),
  patronsText: gettext.$gettext("Spolupráce s našimi sběrateli a influencery posouvá platformu Colectio na vyšší level, děkujeme."),
  patrons: [Patron.Benda, Patron.Saviory, Patron.Investyr, Patron.HonzovyKostky, Patron.MikeLacroix],
});

export const factoryLandingPageSetupLegoSets = (): LandingPageSetup => ({
  categoryId: CategoryId.lego,
  heroImage: [
    { url: "/images/homepage/collage-lego-sets.webp", mime: "image/webp" }, //
    { url: "/images/homepage/collage-lego-sets.avif", mime: "image/avif" },
    { url: "/images/homepage/collage-lego-sets.png", mime: "image/png" },
  ],
  heroText: [translate("views.publicHomepage.hero.categories.lego")],
  itemsText: gettext.$gettext("Sbírej, prodávej, vyměňuj. To vše je možné v&nbsp;naší sběratelské LEGO® sekci."),
  collectorsText: gettext.$gettext("Prohlédni si portfolia ostatních sběratelů a&nbsp;objev, kdo má stejnou vášeň pro LEGO® jako ty."),
  magazineText: gettext.$gettext("Sleduj náš magazín a&nbsp;objevuj novinky a&nbsp;tipy z&nbsp;LEGO® světa."),
  patronsText: gettext.$gettext("Spolupráce s našimi sběrateli a influencery posouvá platformu Colectio na vyšší level, děkujeme."),
  patrons: [Patron.Saviory, Patron.HonzovyKostky, Patron.Benda, Patron.Investyr, Patron.MikeLacroix],
});
