import { useGettext } from "vue3-gettext";

export type PatronData = {
  name: string;
  nickname: string;
  image: string;
  label: string;
  description: string;
};

export enum Patron {
  Benda,
  Saviory,
  HonzovyKostky,
  Investyr,
  MikeLacroix,
}

export function usePatrons() {
  const { $gettext } = useGettext();

  const patrons: Record<Patron, PatronData> = {
    [Patron.Benda]: {
      name: "Karlos Benda",
      nickname: "KarlosBenda",
      image: "/images/patrons/benda.png",
      label: $gettext("Colectio Patron"),
      description: $gettext("Karlos Benda je český bojovník MMA, který soutěží v organizaci Clash of the Stars, influencer a zároveň vášnivý sběratel především popkulturních předmětů. Inspirujte se na jeho profilu."),
    },
    [Patron.Saviory]: {
      name: "Michal Saviory",
      nickname: "MichalSaviory",
      image: "/images/patrons/saviory.png",
      label: $gettext("Colectio Patron LEGO®"),
      description: $gettext("Michal Saviory je významný sběratel a prodejce LEGA®, CEO ve společnosti Saviory Bricks a LEGO® youtuber. Zabývá se prodejem jednotlivých LEGO® dílků a figurek do celého světa."),
    },
    [Patron.HonzovyKostky]: {
      name: "Honzovy Kostky",
      nickname: "Honzovy_Kostky",
      image: "/images/patrons/honzovyKostky.png",
      label: $gettext("Colectio Patron LEGO®"),
      description: $gettext("Honza Pešl je youtuber a LEGO® nadšenec, který stojí za kanálem Honzovy kostky, které se věnují novinkám, recenzím a streamování stavění, převážně LEGO® STAR WARS."),
    },
    [Patron.Investyr]: {
      name: "Investýř",
      nickname: "Investyr",
      image: "/images/patrons/investyr.png",
      label: $gettext("Colectio Patron"),
      description: $gettext("Tomáš Zahradník se specializuje na investice do sběratelských předmětů všeho druhu. Pomáhá lidem s investicemi do sběratelství skrz svoje sociální sítě."),
    },
    [Patron.MikeLacroix]: {
      name: "MikeLacroix",
      nickname: "MikeLacroix",
      image: "/images/patrons/mikelacroix.png",
      label: $gettext("Colectio Patron kartiček"),
      description: $gettext("Filip Šimanovský je vášnivý sběratel hokejových kartiček, především Connora McDavida. Je odborníkem na svět sportovních karet, fanouškem NHL a hokeje obecně."),
    },
  };

  const get = (patron: Patron) => patrons[patron];

  return {
    patrons,
    get,
  };
}
