export default {
  publicCollectionItemCard: {
    collector: "Sběratel",
    offeringFor: "Cena",
    notForSale: "neni k prodeji",
    amount: "Množství",
    amountUnit: "ks",
    imInterested: "Mám zájem",
  },
};
