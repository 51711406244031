import { CollectionItem } from "@/services/repositories/collection/items";
import { createUrlParams, PublicCollectionItem, PublicCollectionItemsFilter } from "@/services/repositories/publicCollectionItems";
import { Layout } from "@/types";
import { RouteLocationRaw } from "vue-router";
import urlSlug from "url-slug";
import { Category } from "@/services/repositories/collection/categories";
import { CatalogueItem, CatalogueSearchItem } from "@/services/repositories/database";
import { Filter as EbayFilter } from "@/services/ebay";
import { ChatUrlFlags } from "@/services/chat";
import { RouteRecordRaw } from "vue-router";
import { gettext } from "@/services/translation";
import { createLocalizedRoute } from "@/router/helpers";

export enum AppRouteName {
  myItemDetail = "myCollectionItemDetail",
  collectionCategory = "collectionCategory",
  myCollection = "collectionSummary",
  catalogue = "catalogueStart",
  catalogueItem = "catalogueItem",
  catalogueCategory = "catalogueCategory",
  dashboard = "dashboard",
  notifications = "notifications",
  userSettings = "profileSettings",
  myProfile = "myProfile",
  articleDraft = "articleDraft",
  ebayListing = "ebay",
  chat = "chat",
  chatConversation = "chatConversation",
  chatEmailNotificationsUnsubscribed = "chatUnsubscribed",
  chatEmailNotificationsUnsubscribeError = "chatUnsubscribeError",
}

export const appRoutes: RouteRecordRaw[] = [
  /**
   * Magazine
   */

  {
    name: AppRouteName.articleDraft,
    path: "magazine/preview/:id(\\d+)-:url/",
    components: { mainContent: () => import("@/views/ArticleDetail.vue") },
    meta: {
      layout: { name: Layout.app },
    },
  },

  /**
   * My collection
   */

  {
    path: "collection",
    children: [
      {
        path: "",
        name: AppRouteName.myCollection,
        components: { mainContent: () => import("@/views/app/myCollection/Overview.vue") },
        meta: {
          title: () => gettext.$gettext("Moje sbírka"),
          layout: { name: Layout.app },
          breadcrumbs: [{ text: () => gettext.$gettext("Moje sbírka") }],
        },
      },
      {
        name: AppRouteName.collectionCategory,
        path: ":url",
        components: { mainContent: () => import("@/views/app/myCollection/Category.vue") },
        meta: {
          layout: { name: Layout.app },
          breadcrumbs: [{ to: { name: AppRouteName.myCollection }, text: () => gettext.$gettext("Moje sbírka") }],
        },
      },
    ],
  },

  /**
   * My item detail
   */

  {
    name: AppRouteName.myItemDetail,
    path: "my-item/:id(\\d+)/:nameSeoSlug?",
    components: { mainContent: () => import("@/views/app/myCollection/ItemDetail.vue") },
    meta: {
      layout: { name: Layout.app },
    },
  },

  /**
   * Dashboard
   */

  {
    name: AppRouteName.dashboard,
    path: "dashboard",
    components: { mainContent: () => import("@/views/app/AppDashboard.vue") },
    meta: {
      title: () => gettext.$gettext("Nástěnka"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Nástěnka") }],
    },
  },

  /**
   * Database
   */

  {
    path: "database",
    children: [
      {
        path: "",
        name: AppRouteName.catalogue,
        components: { mainContent: () => import("@/views/app/catalogue/Intro.vue") },
        meta: {
          title: () => gettext.$gettext("Databáze"),
          breadcrumbs: [{ text: () => gettext.$gettext("Databáze") }],
          layout: { name: Layout.app },
        },
      },
      {
        name: AppRouteName.catalogueCategory,
        path: ":categoryUrl",
        components: { mainContent: () => import("@/views/app/catalogue/Category.vue") },
        meta: {
          title: () => gettext.$gettext("Databáze"),
          breadcrumbs: [{ text: () => gettext.$gettext("Databáze"), to: { name: AppRouteName.catalogue } }],
          layout: { name: Layout.app },
        },
      },
      {
        name: AppRouteName.catalogueItem,
        path: ":categoryUrl/:itemId(\\d+)/:nameSeoSlug?",
        components: { mainContent: () => import("@/views/app/catalogue/ItemDetail.vue") },
        meta: {
          title: () => gettext.$gettext("Detail předmětu"),
          breadcrumbs: [{ text: () => gettext.$gettext("Databáze"), to: { name: AppRouteName.catalogue } }],
          layout: { name: Layout.app },
        },
      },
    ],
  },

  /**
   * User profile
   */

  {
    name: AppRouteName.userSettings,
    path: "profile-settings",
    components: { mainContent: () => import("@/views/app/UserSettings.vue") },
    meta: {
      title: () => gettext.$gettext("Nastavení profilu"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Nastavení profilu") }],
    },
  },
  {
    name: AppRouteName.myProfile,
    path: "profile",
    components: { mainContent: () => import("@/views/app/ProfileMy.vue") },
    meta: {
      title: () => gettext.$gettext("Můj profil"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Můj profil") }],
      preventScrollToTop: true,
    },
  },

  /**
   * Ebay
   */

  {
    name: AppRouteName.ebayListing,
    path: "price-calculator",
    components: { mainContent: () => import("@/views/app/EbayListing.vue") },
    meta: {
      title: () => gettext.$gettext("Naceňovač"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Naceňovač") }],
    },
  },

  /**
   * Chat
   */
  {
    path: "messages/email-unsubscription-successfull",
    name: AppRouteName.chatEmailNotificationsUnsubscribed,
    components: { mainContent: () => import("@/views/ChatEmailNotificationsUnsubscribed.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("E-mailové notifikace zrušeny"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          to: { name: AppRouteName.chat },
          text: () => gettext.$gettext("Zprávy"),
        },
        {
          text: () => gettext.$gettext("E-mailové notifikace zrušeny"),
        },
      ],
    },
  },
  {
    path: "messages/email-unsubscription-failed",
    name: AppRouteName.chatEmailNotificationsUnsubscribeError,
    components: { mainContent: () => import("@/views/ChatEmailNotificationsUnsubscribeError.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("E-mailové notifikace se nepodařilo zrušit"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [
        {
          to: { name: AppRouteName.chat },
          text: () => gettext.$gettext("Zprávy"),
        },
        {
          text: () => gettext.$gettext("E-mailové notifikace se nepodařilo zrušit"),
        },
      ],
    },
  },
  {
    path: "messages",
    meta: {
      title: () => gettext.$gettext("Zprávy"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Zprávy") }],
    },
    children: [
      {
        path: "",
        name: AppRouteName.chat,
        components: { mainContent: () => import("@/views/app/ChatView.vue") },
      },
      {
        path: ":buddyId(\\d+)/:buddyNickname",
        name: AppRouteName.chatConversation,
        components: { mainContent: () => import("@/views/app/ChatView.vue") },
      },
    ],
  },

  /**
   * Others
   */

  {
    name: AppRouteName.notifications,
    path: "notifications",
    components: { mainContent: () => import("@/views/app/NotificationsList.vue") },
    meta: {
      title: () => gettext.$gettext("Notifikace"),
      layout: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Notifikace") }],
    },
  },
];

export const createMyItemDetailLink = (item: CollectionItem | PublicCollectionItem): RouteLocationRaw =>
  createLocalizedRoute({
    name: AppRouteName.myItemDetail,
    params: {
      id: item.getId(),
      nameSeoSlug: urlSlug(item.name),
    },
  });

export const createCollectionCategoryLink = (categoryUrl: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: AppRouteName.collectionCategory,
    params: {
      url: categoryUrl,
    },
  });

export const createMyCollectionLink = (): RouteLocationRaw => createLocalizedRoute({ name: AppRouteName.myCollection });

export const createCatalogueLink = (): RouteLocationRaw => createLocalizedRoute({ name: AppRouteName.catalogue });

export const createCatalogueItemLink = (category: Category, item: CatalogueItem | CatalogueSearchItem): RouteLocationRaw => {
  const categoryUrl = category.getUrl();
  const itemId = item.getId();
  const nameSeoSlug = urlSlug(item.getName());
  return createLocalizedRoute({
    name: AppRouteName.catalogueItem,
    params: { categoryUrl, itemId, nameSeoSlug },
  });
};

export const createCatalogueCategoryLink = (category: Category): RouteLocationRaw =>
  createLocalizedRoute({
    name: AppRouteName.catalogueCategory,
    params: {
      categoryUrl: category.getUrl(),
    },
  });

export const createDashboardLink = (): RouteLocationRaw => createLocalizedRoute({ name: AppRouteName.dashboard });

export const createNotificationsLink = (): RouteLocationRaw => createLocalizedRoute({ name: AppRouteName.notifications });

export const createUserSettingsLink = (anchor?: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: AppRouteName.userSettings,
    hash: anchor ? "#" + anchor : undefined,
  });

export const createMyProfileLink = (filter?: PublicCollectionItemsFilter): RouteLocationRaw =>
  createLocalizedRoute({
    name: AppRouteName.myProfile,
    query: filter ? createUrlParams(filter) : undefined,
  });

export const createEbayListingLink = (filter?: EbayFilter): RouteLocationRaw => {
  if (filter) {
    return createLocalizedRoute({
      name: AppRouteName.ebayListing,
      query: {
        q: filter.q,
        sort: filter.sort,
        category: filter.category,
      },
    });
  } else {
    return createLocalizedRoute({ name: AppRouteName.ebayListing });
  }
};

export const createChatLink = (): RouteLocationRaw => createLocalizedRoute({ name: AppRouteName.chat });

export const createConversationLink = (buddyId: number, buddyNickname: string, flags?: ChatUrlFlags): RouteLocationRaw =>
  createLocalizedRoute({
    name: AppRouteName.chatConversation,
    params: { buddyId, buddyNickname },
    query: flags,
  });
