import { Layout } from "@/types";
import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { gettext } from "@/services/translation";
import { createLocalizedRoute } from "@/router/helpers";

export enum ErrorRouteName {
  error404 = "error404",
}

export const errorPagesRoutes: RouteRecordRaw[] = [
  {
    name: ErrorRouteName.error404,
    path: "/:countryCode(cz|sk)/:pathMatch(.*)",
    components: { mainContent: () => import("@/views/error/NotFound404.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Oops! Zabloudil jsi…"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
];

export const createError404Link = (): RouteLocationRaw =>
  createLocalizedRoute({
    name: ErrorRouteName.error404,
    params: {
      pathMatch: "404",
    },
  });
