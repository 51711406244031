import { CurrencyId } from "@/services/currencies";

const EmptyPriceValue = null;

/* Types */
export type PriceNumeric = { currencyId: CurrencyId; price: number };
export type PriceEmpty = typeof EmptyPriceValue;
export type PriceNumericOrEmpty = PriceNumeric | PriceEmpty;
export enum PriceSpecial {
  Negotiable = "negotiable",
}
export type Price = PriceNumeric | PriceSpecial | PriceEmpty;

/* Validators */
export const isNegotiablePrice = (price: unknown): price is PriceSpecial.Negotiable => price === PriceSpecial.Negotiable;
export const isEmptyPrice = (price: unknown): price is PriceEmpty => price === EmptyPriceValue;
export const isNotForSalePrice = (price: unknown): price is PriceEmpty => price === EmptyPriceValue;
export const isNumericPrice = (price: unknown): price is PriceNumeric => price !== PriceSpecial.Negotiable && price !== EmptyPriceValue && typeof price === "object" && price !== EmptyPriceValue && "currencyId" in price && "price" in price;
export const isNumericOrEmptyPrice = (price: unknown): price is PriceNumericOrEmpty => isEmptyPrice(price) || isNumericPrice(price);
export const assertPrice = (value: unknown): Price => {
  if (isEmptyPrice(value) || isNotForSalePrice(value) || isNegotiablePrice(value) || isNumericPrice(value)) {
    return value;
  } else {
    throw new InvalidPriceException();
  }
};

/* Comparators */
export const isEqualPrice = (priceA: Price, priceB: Price): boolean => {
  if (isNumericPrice(priceA) && isNumericPrice(priceB)) {
    return priceA.price === priceB.price && priceA.currencyId === priceB.currencyId;
  } else if (isEmptyPrice(priceA) && isEmptyPrice(priceB)) {
    return true;
  } else if (isNegotiablePrice(priceA) && isNegotiablePrice(priceB)) {
    return true;
  }

  return false;
};

/* Factories */
export const createNegotiablePrice = (): PriceSpecial => PriceSpecial.Negotiable;
export const createEmptyPrice = (): PriceEmpty => EmptyPriceValue;
export const createNotForSalePrice = (): PriceEmpty => EmptyPriceValue;
export const createNumericPrice = (price: number, currencyId: CurrencyId): PriceNumeric => ({ price, currencyId });
export const createPrice = (price: number | null, currencyId: number | null): Price => {
  if (price === EmptyPriceValue) {
    return createEmptyPrice();
  } else if (currencyId === null) {
    return createNegotiablePrice();
  } else {
    return createNumericPrice(price, currencyId);
  }
};

/* Exceptions */
export class InvalidPriceException extends Error {}
