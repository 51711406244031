import { translate } from "./services/translation";

export const NoValuePlaceholder = "–";
export const ArticleWidgetMaxIdentificatorLength = 70;

export const DefaultItemsCountPerPage = 20;
export const CatalogueItemsCountPerPage = 20;
export const CatalogueListingNoValuePlaceholder = "?";

export const DashboardLastItemsLimit = 3;
export const NotificationsLimit = 10;

export const UserSettings = {
  nameMaxLength: 60,
  nicknameMinLength: 2,
  nicknameMaxLength: 30,
  bioMaxLength: 150,
  passwordMinLength: 8,
};

export const CollectionItem = {
  noteMaxLength: 9000,
  whereStoredMinLength: 0,
  whereStoredMaxLength: 255,
  acquisitionSourceMin: 0,
  acquisitionSourceMax: 255,
  nameMinLength: 1,
  nameMaxLength: 450,
  acquirePriceMin: 0,
  acquirePriceMax: 42949672.95,
  currentPriceMin: 0,
  currentPriceMax: 42949672.95,
  piecesMin: 1,
  piecesMax: 9999,
  publicOfferedPriceMin: 1,
  publicOfferedPriceMax: 42949672.95,
  publicPiecesMin: 1,
  publicPiecesMax: 9999,
  priceAdjustmentMax: 10000,
  priceAdjustmentMin: -100,
};

export const Company = {
  name: "Colectio s.r.o.",
  taxId: "29309069",
  court: translate("views.contacts.court", { city: "Brně", itemNo: "C 73039" }),
  street: "Dobrovského",
  building: "675/4",
  district: "Královo Pole",
  city: "Brno",
  zip: "612 00",
  map: "https://maps.app.goo.gl/FCeQsyFXPr7xiHVh7",
  email: "info@colectio.com",
};

export const Socials = {
  instagram: "https://www.instagram.com/colectio.cz/",
  facebook: "https://www.facebook.com/Colectio.cz",
  linkedin: "https://www.linkedin.com/company/colectio-app/",
};

export const Website = {
  name: "Colectio",
  domain: window.location.host,
  protocol: window.location.protocol,
};

export const Magazine = {
  articlesPerPage: 12,
};

export const PublicCollectionItems = {
  listing: {
    perPage: 24,
    filterDebounceTime: 300,
  },
};

export const Collectors = {
  perPage: 48,
  filterDebounceTime: 300,
};

export const ProfileSettings = {
  itemsPerPage: 24,
};

export const Chat = {
  maxMessageLength: 12000, // ~65k bytes, decided by DEV team 25.6.2024
};
