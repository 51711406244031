import { Layout, OnlyPublicProperties } from "@/types";
import { gettext } from "@/services/translation";
import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { AppRouteName } from "./appRoutes";
import { createUrlParams, PublicCollectionItemsFilter } from "@/services/repositories/publicCollectionItems";
import urlSlug from "url-slug";
import { Category } from "@/services/repositories/collection/categories";
import { CollectorsFilter } from "@/services/repositories/collectors";
import { createLocalizedRoute } from "@/router/helpers";
import { factoryLandingPageSetupHomepage, factoryLandingPageSetupLegoSets } from "@/services/landingPages";

export enum PublicRouteName {
  profile = "publicProfile",
  publicHomepage = "homepage",
  publicItemDetail = "publicItemCard",
  about = "about",
  gdpr = "gdpr",
  tac = "tac",
  guide = "guide",
  magazine = "articlesList",
  article = "article",
  contacts = "contacts",
  books = "booksDownload",
  publicItemsList = "publicItemsList",
  collectors = "collectors",
}

export const publicRoutes: RouteRecordRaw[] = [
  /**
   * Landing pages:
   */

  {
    name: PublicRouteName.publicHomepage,
    path: "",
    components: { mainContent: () => import("@/views/public/LandingPage.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Objevuj svět sběratelů"),
      layout: { name: Layout.public },
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
      landingPageDataFactory: factoryLandingPageSetupHomepage,
    },
  },
  {
    path: "lego-sets",
    components: { mainContent: () => import("@/views/public/LandingPage.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Lego®"),
      layout: { name: Layout.public },
      redirectWhenLoggedIn: { name: AppRouteName.dashboard },
      landingPageDataFactory: factoryLandingPageSetupLegoSets,
    },
  },
  {
    path: "watches",
    components: { mainContent: () => import("@/views/public/LandingPageWatch.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Hodinky"),
      layout: { name: Layout.public },
    },
  },
  {
    name: PublicRouteName.gdpr,
    path: "privacy-policy",
    components: { mainContent: () => import("@/views/Gdpr.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Zásady ochrany osobních údajů"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.tac,
    path: "terms-and-conditions",
    components: { mainContent: () => import("@/views/TermsAndConditions.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Všeobecné obchodní podmínky"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.guide,
    path: "guide",
    components: { mainContent: () => import("@/views/Guide.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Právní informace"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.contacts,
    path: "contacts",
    components: { mainContent: () => import("@/views/ContactPage.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Kontakty"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.about,
    path: "about-us",
    components: { mainContent: () => import("@/views/AboutUs.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("O nás"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.books,
    path: "guides",
    components: { mainContent: () => import("@/views/BooksDownload.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Příručky"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },

  /**
   * Magazine
   */
  {
    path: "magazine",
    children: [
      {
        name: PublicRouteName.article,
        path: ":id(\\d+)-:url",
        components: { mainContent: () => import("@/views/ArticleDetail.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Magazín"),
          layout: { name: Layout.public },
          layoutLoggedIn: { name: Layout.app },
        },
      },
      {
        name: PublicRouteName.magazine,
        path: ":category?",
        components: { mainContent: () => import("@/views/ArticlesList.vue") },
        meta: {
          public: true,
          title: () => gettext.$gettext("Magazín"),
          layout: { name: Layout.public },
          layoutLoggedIn: { name: Layout.app },
          breadcrumbs: [{ text: () => gettext.$gettext("Magazín") }],
        },
      },
    ],
  },

  /**
   * Profiles
   */
  {
    name: PublicRouteName.publicItemDetail,
    path: "collector/:nickname/:slug/:itemId",
    components: { mainContent: () => import("@/views/public/PublicItemDetail.vue") },
    meta: {
      public: true,
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },
  {
    name: PublicRouteName.profile,
    path: "collector/:nickname",
    components: { mainContent: () => import("@/views/public/ProfilePublic.vue") },
    meta: {
      public: true,
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
    },
  },

  /**
   * Public items listing
   */
  {
    name: PublicRouteName.publicItemsList,
    path: "collectors-items",
    components: { mainContent: () => import("@/views/PublicItems.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Předměty sběratelů"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Předměty sběratelů") }],
    },
  },

  /**
   * Collectors list
   */
  {
    name: PublicRouteName.collectors,
    path: "collectors",
    components: { mainContent: () => import("@/views/Collectors.vue") },
    meta: {
      public: true,
      title: () => gettext.$gettext("Sběratelé"),
      layout: { name: Layout.public },
      layoutLoggedIn: { name: Layout.app },
      breadcrumbs: [{ text: () => gettext.$gettext("Sběratelé") }],
    },
  },
];

export const createProfileLink = (nickname: string, filter?: OnlyPublicProperties<PublicCollectionItemsFilter>): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.profile,
    params: {
      nickname: nickname,
    },
    query: filter ? createUrlParams(filter) : undefined,
  });

export const createHomepageLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.publicHomepage });

export const createPublicItemDetailLink = (itemId: number, itemName: string, collectorNickname: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.publicItemDetail,
    params: {
      nickname: urlSlug(collectorNickname),
      itemId: itemId,
      slug: urlSlug(itemName) || "-",
    },
  });

export const createAboutLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.about });

export const createGdprLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.gdpr });
export const createTacLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.tac });
export const createGuideLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.guide });

export const createArticleLink = (id: number, url: string): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.article,
    params: { id, url },
  });

export const createMagazineLink = (category?: Category): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.magazine,
    params: {
      category: category ? category.url : undefined,
    },
  });

export const createContactsLink = (): RouteLocationRaw => createLocalizedRoute({ name: PublicRouteName.contacts });

export const createPublicItemsListLink = (filter?: OnlyPublicProperties<PublicCollectionItemsFilter>): RouteLocationRaw => {
  if (filter) {
    return createLocalizedRoute({
      name: PublicRouteName.publicItemsList,
      query: createUrlParams(filter),
    });
  } else {
    return createLocalizedRoute({ name: PublicRouteName.publicItemsList });
  }
};

export const createCollectorsLink = (filter?: CollectorsFilter.Filter): RouteLocationRaw =>
  createLocalizedRoute({
    name: PublicRouteName.collectors,
    query: filter?.createUrlParams(),
  });
