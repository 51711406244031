import { CurrencyId, FallbackCurrencyId } from "@/services/currencies";
import { CountryCode, FallbackCountryCode } from "@/services/translation";
import { User } from "@/services/user";
import { defineStore } from "pinia";

type UserStoreValue = undefined | User;
type AccessTokenStoreValue = string | undefined;

type UserStoreState = {
  user: UserStoreValue;
  ssoAccessToken: AccessTokenStoreValue;
  countryCode: CountryCode | undefined;
  currencyId: CurrencyId | undefined;
};

export const useUserStore = defineStore("user", {
  state: () =>
    ({
      user: undefined,
      ssoAccessToken: undefined,
      countryCode: undefined,
      currencyId: undefined,
    } as UserStoreState),

  actions: {
    get(): UserStoreValue {
      return this.user as UserStoreValue;
    },
    getSsoAccessToken(): AccessTokenStoreValue {
      return this.ssoAccessToken as AccessTokenStoreValue;
    },
    saveSsoAccessToken(token: string): void {
      this.ssoAccessToken = token;
    },
    clearSsoAccessToken(): void {
      this.ssoAccessToken = undefined;
    },
    login(user: User) {
      this.user = user;
    },
    logout() {
      this.user = undefined;
    },
    setCountryCode(code: CountryCode): void {
      this.countryCode = code;
    },
    getCountryCode(): CountryCode {
      return this.countryCode ?? FallbackCountryCode;
    },
    setCurrencyId(currencyId: CurrencyId): void {
      this.currencyId = currencyId;
    },
    getCurrencyId(): CurrencyId {
      return this.currencyId ?? FallbackCurrencyId;
    },
  },
});
