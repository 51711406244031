import axios from "axios";
import { handleError } from "./errorHandlers";
import { requireUser } from "@/services/user";
import { CurrencyIdQueryParamName, LocaleQueryParamName, TokenHeader } from "@/services/api2";
import { useUserStore } from "@/store";

export const TokenInUrlStringReplacement = "api-obsolete";

export const API = {
  get,
  getWithParams,
  post,
  put,
};

async function get(url, addToken = true) {
  const options = { headers: {} };
  options.url = await createFullUrl(url, addToken);
  options.params = createGetParams();

  if (addToken) {
    const token = requireUser().getToken();
    options.headers[TokenHeader] = token;
  }

  const response = await axios.get(options.url, options).catch((e) => {
    handleError(e);
  });

  return response.data;
}

async function getWithParams(url, params, addToken = true) {
  const options = { headers: {} };
  options.url = await createFullUrl(url, addToken);
  options.params = createGetParams(params);

  if (addToken) {
    const token = requireUser().getToken();
    options.headers[TokenHeader] = token;
  }

  const response = await axios.get(options.url, options).catch((e) => {
    handleError(e);
  });

  return response.data;
}

async function post(url, data, headers = {}, addToken = true, progressListener) {
  const options = {};
  options.method = "post";
  options.url = await createFullUrl(url, addToken);
  options.data = data;
  options.headers = headers;
  options.params = createGetParams();

  if (progressListener) {
    options.onUploadProgress = progressListener;
  }

  if (addToken) {
    const token = requireUser().getToken();
    options.headers[TokenHeader] = token;
  }

  return axios(options)
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

async function put(url, data = {}, headers = {}, addToken = true) {
  const options = {};
  options.method = "put";
  options.url = await createFullUrl(url, addToken);
  options.data = data;
  options.headers = headers;
  options.params = createGetParams();

  if (addToken) {
    const token = requireUser().getToken();
    options.headers[TokenHeader] = token;
  }

  return axios(options)
    .then((response) => response.data)
    .catch((e) => handleError(e));
}

async function createFullUrl(url, addToken) {
  let fullUrl = import.meta.env.VITE_API_URL;

  if (addToken) {
    fullUrl += TokenInUrlStringReplacement + "/";
  }

  fullUrl += url;

  return fullUrl;
}

const createGetParams = (params = {}) => ({
  ...params,
  [LocaleQueryParamName]: useUserStore().getCountryCode(),
  [CurrencyIdQueryParamName]: useUserStore().getCurrencyId(),
});
