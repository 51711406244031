import { Website } from "@/config";
import { LanguageCode } from "@/services/translation";

const setLinkCanonical = (url: string) => {
  const linkCanonical = document.querySelector('head link[rel="canonical"]');
  if (linkCanonical) {
    linkCanonical.setAttribute("href", url);
  } else {
    const newLinkCanonical = document.createElement("link");
    newLinkCanonical.rel = "canonical";
    newLinkCanonical.href = url;
    document.head.appendChild(newLinkCanonical);
  }
};

const setLinkAlternate = (alternateLinks: { lang: LanguageCode; url: string }[]) => {
  const existingLinks = document.querySelectorAll('head link[rel="alternate"]');
  existingLinks.forEach((link) => link.remove());

  alternateLinks.forEach((link) => {
    const linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "alternate");
    linkElement.setAttribute("hreflang", link.lang);
    linkElement.setAttribute("href", link.url);
    document.head.appendChild(linkElement);
  });
};

const setTitle = (title?: string): void => {
  if (title) {
    document.title = title + " | " + Website.name;
  } else {
    document.title = Website.name;
  }
};

export const MetaTags = {
  setLinkCanonical,
  setLinkAlternate,
  setTitle,
};
