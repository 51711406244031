import { createI18n } from "vue-i18n";
import csTexts from "@/locales/cs";
import skTexts from "@/locales/sk";
import translations from "@/locales/translations.json";
import { createGettext } from "vue3-gettext";
import { useUserStore, store as pinia } from "@/store";
import { getEnumKeyByValue } from "@/utilities/enum";
import { CurrencyId } from "@/services/currencies";

export enum LanguageCode {
  cs = "cs",
  sk = "sk",
}

export enum LanguageId {
  Cs = 1,
  Sk = 2,
}

export enum CountryCode {
  Cz = "cz",
  Sk = "sk",
}

export const LanguageCode2NameMap: Record<LanguageCode, string> = {
  [LanguageCode.cs]: "Čeština",
  [LanguageCode.sk]: "Slovenčina",
};

export const LanguageCode2LanguageIdMap: Record<LanguageCode, LanguageId> = {
  [LanguageCode.cs]: LanguageId.Cs,
  [LanguageCode.sk]: LanguageId.Sk,
};

export const LanguageId2CountryCodeMap: Record<LanguageId, CountryCode> = {
  [LanguageId.Cs]: CountryCode.Cz,
  [LanguageId.Sk]: CountryCode.Sk,
};

/**
 * See https://github.com/HatScripts/circle-flags for available codes.
 */
export const LanguageCode2FlagMap: Record<LanguageCode, string> = {
  [LanguageCode.cs]: "cz",
  [LanguageCode.sk]: "sk",
};

const NavigatorLanguage2CountryCodeMap: Record<string, CountryCode> = {
  ["cs"]: CountryCode.Cz,
  ["cs-CZ"]: CountryCode.Cz,
  ["sk"]: CountryCode.Sk,
  ["sk-SK"]: CountryCode.Sk,
};

export const CountryCode2LanguageCodeMap: Record<CountryCode, LanguageCode> = {
  [CountryCode.Cz]: LanguageCode.cs,
  [CountryCode.Sk]: LanguageCode.sk,
};

export const LanguageCode2DefaultCurrencyIdMap: Record<LanguageCode, CurrencyId> = {
  [LanguageCode.cs]: CurrencyId.Czk,
  [LanguageCode.sk]: CurrencyId.Eur,
};

export const FallbackLanguageCode = LanguageCode.cs;
export const FallbackCountryCode = CountryCode.Cz;

/** @deprecated */
const PluralRules = {
  [LanguageCode.cs]: function (count: number): number {
    if (count === 0) {
      return 0;
    } else if (count === 1) {
      return 1;
    } else if (count < 5) {
      return 2;
    } else {
      return 3;
    }
  },
};

/** @deprecated */
export const i18n = createI18n({
  locale: LanguageCode.cs,
  fallbackLocale: FallbackLanguageCode,
  warnHtmlMessage: false,
  legacy: false,
  globalInjection: true,
  messages: {
    [LanguageCode.cs]: csTexts,
    [LanguageCode.sk]: skTexts,
  },
  pluralRules: PluralRules,
  missing: (locale, key) => {
    console.error(`Translation missing for key: "${key}" in locale: "${locale}"`);
    return key;
  },
});

/** @deprecated */
export const translate = i18n.global.t;

export const gettext = createGettext({
  availableLanguages: LanguageCode2NameMap,
  defaultLanguage: FallbackLanguageCode,
  translations,
});

export const setCountry = (country: CountryCode): void => {
  const userStore = useUserStore(pinia);
  userStore.setCountryCode(country);

  const language = CountryCode2LanguageCodeMap[country];
  setLanguage(language);
};

export const setLanguage = (language: LanguageCode): void => {
  i18n.global.locale.value = language;
  gettext.current = language;
  document.documentElement.lang = language;
};

export const getLanguageObject = (languageCode: string) => {
  const code = getEnumKeyByValue(languageCode, LanguageCode);
  const flag = LanguageCode2FlagMap[code];
  const label = LanguageCode2NameMap[code];
  return { code, flag, label };
};

export const autodetectCountryCode = (): CountryCode => {
  const navigatorLang = navigator.userLanguage || navigator.language;
  const countryCode = NavigatorLanguage2CountryCodeMap[navigatorLang];
  return countryCode ?? FallbackCountryCode;
};
