import { CountryCode, CountryCode2LanguageCodeMap, FallbackCountryCode, getLanguageObject, gettext, LanguageCode } from "@/services/translation";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

/**
 * A custom hook for handling translations using i18n.
 *
 * @param {string} [localesNamespace=""] - An optional namespace to prefix translation keys.
 *
 * The `translate` function formats and retrieves localized strings based on the provided message key and arguments.
 * If the message does not start with "@", the function prefixes it with the specified `localesNamespace`.
 */
export function useTranslation(localesNamespace = "") {
  const { t } = useI18n();

  const translate = (message: string, args: Record<string, unknown> = {}): string => {
    let path = message;
    if (message.startsWith("@:")) {
      path = message.substring(2);
    } else if (localesNamespace) {
      path = localesNamespace + "." + message;
    }

    return t(path, args);
  };

  const currentLocale = computed(() => getLanguageObject(gettext.current));

  const router = useRouter();
  const switchLanguage = (newLanguage: LanguageCode) => {
    if (currentLocale.value.code === newLanguage) {
      return;
    }

    const countryCode = Object.keys(CountryCode2LanguageCodeMap).find((key) => CountryCode2LanguageCodeMap[key as CountryCode] === newLanguage) ?? FallbackCountryCode;
    const currentRoute = router.currentRoute.value;
    const newRoute = {
      name: currentRoute.name,
      query: currentRoute.query,
      hash: currentRoute.hash,
      meta: currentRoute.meta,
      matched: currentRoute.matched,
      redirected: currentRoute.redirectedFrom,
      params: {
        ...currentRoute.params,
        countryCode,
      },
    };
    const url = router.resolve(newRoute);
    window.location.href = url.fullPath;
  };

  return {
    translate,
    currentLocale,
    switchLanguage,
  };
}
